<template>
  <div class="w-full flex justify-center items-center">
    <div class="flex justify-center mx-auto ">
     <div class="text-red-500 text-lg flex flex-col justify-center text-center items-center border-slate-200 shadow-lg rounded border p-6 max-w-2xl">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-12 h-12 mb-2">
        <path fill-rule="evenodd" d="M6.72 5.66l11.62 11.62A8.25 8.25 0 006.72 5.66zm10.56 12.68L5.66 6.72a8.25 8.25 0 0011.62 11.62zM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788z" clip-rule="evenodd" />
      </svg>
      <span>
        {{ $t('error.not.found') }}
      </span>
     </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>

</style>